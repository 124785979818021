button {
  padding: 10px 20px;
  font-size: 16px;
  margin-bottom: 10px;
  cursor: pointer;
}

.modal2 {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content2 {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 500px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modalHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.modalHeader h2 {
  font-size: 25px;
}

.assignedTo {
  margin-bottom: 10px;
  font-size: 25px;
}

.or-text {
  margin: 5%;
}


.content {
  flex-grow: 1;
  padding: 2%;
  padding-left: 2%;
  width: 100%;
  overflow-y: scroll;
}


.comment-list {
  list-style: none;
  padding: 10px;
  max-height: 200px;
  overflow: scroll;
  overflow-x: hidden;
}

.comment-list li {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  max-width: max-content;
}

.inputAndButtonWrapper {
  position: relative;
}

.inputAndButtonWrapper button {
  border-radius: 5px;
}

.showButton {
  border-radius: 5px;
}

.inputAndButtonWrapper button {
  position: absolute;
  top: 0;
  right: -6px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
}

.commentInput {
  width: 100%;
  height: 33px;
  margin-bottom: 15px;
  border-radius: 5px;
}

.commentInput::placeholder {
  padding-left: 10px;
}

.sent {
  background-color: #d1e7dd;
  text-align: right;
  margin-left: auto;
}

.received {
  background-color: #f8d7da;
  text-align: left;
}

.burgerMenu {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  display: none;
}

.burgerMenu img {
  width: 30px;
  height: 30px;
}

.sidebar-links-container.open {
  width: 100px;
  transition: all 0.1s ease-in-out;
}

@media screen and (max-width: 600px) {
  .modal-content {
    width: 95%;
  }

  .sidebar-links-container {
    width: 0px;
    transition: all 0.1s ease-in-out;
  }

  .burgerMenu {
    display: block;
  }

  .top-banner-right h1 {
    display: none;
  }

  .imageAndTextHolder {
    display: none;
  }

  .sidebar-text {
    display: none;
  }
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 100%;
}

.logoutButton {
  margin-bottom: 12%;
}


.checkbox-option-group {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.checkbox-option-group div {
  display: flex;
  align-items: center;
}

.button-group {
  display: flex;
  gap: 10px;
}

.first_row {
  display: flex;
  flex-direction: row;
  width: 100%;
  place-content: space-between;
  max-width: 94%;
  align-items: center;
  margin-bottom: 15px;
}

.auto-resize-textarea {
  width: 94%;
  height: 100px;
  padding-left: 10px;
  box-sizing: border-box;
  resize: vertical;
}

.font-family {
  font-family: 'Poppins', sans-serif;
}

.user-info {
  position: relative;
}

.dropdown-arrow-icon {
  cursor: pointer;
}


.verfication-notification-error.show {
  right: 0%;
  top: 4%;
}

.dropdown-menu {
  position: absolute;
  top: 80%;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 14px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  display: flex;
  flex-direction: column;
  margin-right: 15%;
}

.logout-button {
  border: none;
  background: none;
  color: #4C208C;
  font-family: Poppins;
  cursor: pointer;
  font-size: 14px;
  padding: 0;
  margin-left: 5px;
  margin-bottom: 5px;
  margin-right: 5px;
  margin-top: 5px;
}


.loader {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  background:
    radial-gradient(farthest-side, #4C208C 95%, #00000000) 50% 1px/12px 12px no-repeat,
    radial-gradient(farthest-side, #ffffff00 calc(100% - 14px), #ccc 0);
  animation: l9 2s infinite linear;
}

@keyframes l9 {
  to {
    transform: rotate(1turn)
  }
}













/* Base styles for larger screens */

/* Styles specific to iPhone 14 Pro Max */
@media only screen and (max-width: 430px) {
  .top-banner {
    padding: 10px;
    margin-top: 1px;

  }

  /* .ticket-detail {
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    width: 38%;
    box-sizing: border-box;
    overflow-x: auto;
  } */

  .top-banner-left h1,
  .top-banner-left h2 {
    font-size: 55%;
    margin-bottom: 0px;
    margin-top: 0px;
  }


  .top-banner-left img {
    width: 13px;
    height: 13px;
    margin-top: 0px;
    margin-right: 7px;
  }

  .logoutButtonContainer {
    margin-bottom: 48%;
    padding: 5%;
  }

  .user-info {
    flex-direction: column;
    align-items: flex-start;
  }

  .dropdown-arrow-icon {
    margin-top: 3px;
  }

  .dropdown-menu {
    right: auto;
    left: 0;
    top: 100%;
    width: 72%;
    box-shadow: none;
    margin-top: 20px;
  }

  .logout-button {
    font-size: 11px;
    margin-left: 2px;
    margin-top: 2px;

  }

  .logoutButton {
    padding: 15%;
    font-size: 80%;

  }


  .portalTitle {
    color: #000;
    font-size: 68%;
    font-family: Poppins;
    font-weight: 500;
    margin-right: 5%;
    text-align: center;
  }


  .top-banner {
    padding: 10px;
    margin-top: 1px;
  }

  .bell-icon {
    display: none;
  }

  .first_row {
    flex-direction: column;
  }

  .form-group {
    width: 100%;
  }

  .form-group input[type="text"] {
    width: 100%;
  }
}

.verfication-notification {
  position: fixed;
  right: -200%;
  top: 125px;
  background-color: #16C75D;
  color: white;
  padding: 23px;
  box-sizing: border-box;
  border-radius: 12px 0 0 12px;
  transition: right 0.5s ease-in-out;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;
  font-family: "Roboto", arial, sans-serif;
  font-weight: bold;
  font-style: normal;
}

.verfication-notification-error {
  position: fixed;
  right: -200%;
  top: 125px;
  background-color: #cc0000;
  color: white;
  padding: 23px;
  box-sizing: border-box;
  border-radius: 12px 0 0 12px;
  transition: right 0.5s ease-in-out;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;
  font-family: "Roboto", arial, sans-serif;
  font-weight: bold;
  font-style: normal;
}

.verfication-notification .check-icon {
  line-height: 0;
}

.verfication-notification.show {
  right: 0;
  top: 7%;
}

.verfication-notification-error.show {
  right: 0;
}



/* Example for tablets in landscape and portrait orientation */
@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .request {
    background-color: #fff;
    padding: 5%;
    width: 100%;
    border-radius: 20px;
    box-sizing: border-box;
    padding-left: 5%;
    margin-top: 6%;
  }

  .content {
    flex-grow: 1;
    padding: 2%;
    padding-left: 2%;
    width: 100%;
  }

  .top-banner-left h1 {
    color: #000;
    font-size: 86%;
    font-family: Poppins;
    font-weight: 500;
  }

  .logoutButton {
    margin-bottom: 0px;
  }

  /* .ticket-detail {
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    width: 87%;
    box-sizing: border-box;
    overflow-x: auto;
  } */

  .portalTitle {
    color: #000;
    font-size: 19px;
    font-family: Poppins;
    font-weight: 500;
    text-align: center;
    margin-top: 18%;
  }

  .bell-icon {
    display: none;
  }

  .logoutButtonContainer {
    padding: 0px 10px;
    margin-bottom: 10%;
  }

  .top-banner-right h1 {
    display: none;
  }
}